import React from 'react';
import { Review } from 'ekyc-web-module';

const review = ({ sdkToken, identityKycToken, reviewData }) => {
  let data = reviewData?.data || {};

  const props = {
    baseUrl: process.env.GATSBY_API_ENDPOINT,
    ekycToken: sdkToken,
    identityToken: identityKycToken,
    reviewData: data
  };
  return <Review {...props} />;
};

export default review;
